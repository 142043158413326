import React, { useEffect, useRef, memo } from 'react';

function HeatMapWidget() {
  const container = useRef();

  useEffect(() => {
    const scriptId = 'tradingview-heatmap-script'; // Unique ID for the script
    const existingScript = document.getElementById(scriptId);

    // Avoid adding multiple scripts
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = scriptId; // Assign the unique ID
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "dataSource": "Crypto",
          "blockSize": "market_cap_calc",
          "blockColor": "change",
          "locale": "ar_AE",
          "symbolUrl": "",
          "colorTheme": "dark",
          "hasTopBar": true,
          "isDataSetEnabled": true,
          "isZoomEnabled": true,
          "hasSymbolTooltip": true,
          "isMonoSize": false,
          "width": "100%",
          "height": 500
        }`;

      container.current.appendChild(script);
    }
  }, []); // No dependencies, run only on mount

  return (
    <div
      style={{
        padding: '20px', // Padding from all sides
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        boxSizing: 'border-box', // Include padding in the height and width calculations
      }}
    >
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ width: '100%' }}
      >
        <div className="tradingview-widget-container__widget"></div>
      </div>
      <div
        className="tradingview-widget-copyright"
        style={{
          marginTop: '10px', // Space below the widget
          textAlign: 'center', // Center-align the text
          width: '100%', // Ensure full width
        }}
      >
        <a href="https://ar.tradingview.com/" rel="noopener noreferrer" target="_blank">
          <span className="blue-text">تتبع جميع الأسواق على TradingView</span>
        </a>
      </div>
    </div>
  );
}

export default memo(HeatMapWidget);
